import { forwardRef } from 'react';
import { Button } from '@chakra-ui/react';
// eslint-disable-next-line react/display-name
const SKButton = forwardRef((props, ref) => {
  return (
    <Button
      ref={ref}
      colorScheme={'brand'}
      color="white"
      bgGradient="linear(to-r, #028C6A, #5db59c)"
      fontWeight={'normal'}
      {...props}
    >
      {props.children}
    </Button>
  );
});

export default SKButton;
