import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  Link,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { ca } from 'date-fns/locale';
import React, { useRef } from 'react';
import classes from './styles.module.scss';
import SKButton from 'components/UI/SKButton';
import AccountBannedSVG from 'public/assets/icons/account-banned.svg';
import { StorageService } from 'service/Storage';
export default function AccountBlocked({ isOpen, onClose }) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent align="center" bg="#dbece6" borderRadius="12px">
          <ModalHeader color="#52B358" align="center" justifyContent="center">
            <Flex direction="column" alignItems="center">
              <AccountBannedSVG style={{ marginBottom: '1rem' }} />
              <Heading as="h4" size="md" color="#111111">
                Account Suspended
              </Heading>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter
            align="center"
            margin="auto"
            display={'flex'}
            flexDirection="column"
          >
            <div className={classes.bannedText}>
              Your Account has been Suspended for Security Purposes. Contact us
              to know more.
            </div>
            <BannedContactSupport />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export function BannedContactSupport({ mobile = '' }) {
  const emailTemplate = StorageService.getUserId()
    ? `InCash app Account Suspension support for User Id: ${StorageService.getUserId()}  &body=Hello Team, %0D%0AThis email is regarding account suspension support for InCash app. %0D%0AUser Id: ${StorageService.getUserId()} \n`
    : `InCash app Account Suspension support for Mobile Number:${
        mobile || StorageService.getMobile()
      }  &body=Hello Team, %0D%0AThis email is regarding account suspension support for InCash app. %0D%0AMobile Number: ${
        mobile || StorageService.getMobile()
      }\n`;
  return (
    <SKButton
      style={{
        padding: '1.5rem 4rem'
      }}
      py={6}
      fontWeight="normal"
      px={8}
      type="button"
      onClick={() => {
        window.open(
          `mailto:incashappofficial@gmail.com?subject=${emailTemplate}`
        );
      }}
    >
      Contact Support
    </SKButton>
  );
}
